import React, { useState } from "react";
import "./ContactUsPopup.css"; 

import PhoneIcon from '../resources/phone_contactUs.svg'
import MailIcon from '../resources/email.svg' 

import WhatsAppIcon from "../resources/whatsapp_icon.svg"
import BigWhatsAppIcon from "../resources/big_whatsapp_icon.svg"
import TelegramIcon from "../resources/telegram_icon.svg"
import VkIcon from "../resources/vk_icon.svg"
import ContentCopyIcon from "../resources/content_copy.svg"

import constants from "../ContentConstants" 

const IconDataCopy = ({text, icon}) => {

    const [isVisible, setIsVisible] = useState(false);

    const handleClick = () => {
        setIsVisible(true); // Make the element visible
        setTimeout(() => {
            setIsVisible(false); // Start fading it out after a delay
        }, 2000); // Adjust delay as needed
    };

    const copyToClipboard = () => {
        window.focus();
        handleClick()
        navigator.clipboard.writeText(text)
    }

    return (
      <div class="content-item-bg">
        <div class="contact-item">
            <img src={icon} alt="Email" class="icon" />
            <span>{text}</span>
        </div>
        <img src={ContentCopyIcon} class="copy-btn" onClick={copyToClipboard}/>
        <p className={`content-item-copied-p ${isVisible ? "visible" : ""}`}>Скопировано!</p>
      </div>
    );
};

const Popup = ({ isOpen, onClose }) => {

    const sendToWhatsapp = () => {
        let phone = constants.companyPhoneNumber.replaceAll('-','').replaceAll('+','')
        window.location.href = `https://api.whatsapp.com/send?phone=${phone}`
    }

    if (!isOpen) return null;

    return (
        <div className="popup-overlay" onClick={onClose}>
            <div className="popup" onClick={(e) => e.stopPropagation()}>
                <div className="popup-content">
                    <h2>Способы связаться с нами:</h2>
                    <div class="data-copy-holder">
                        <IconDataCopy text={constants.companyPhoneNumber} icon={PhoneIcon}/>
                        <IconDataCopy text={constants.companyEmail} icon={MailIcon}/>
                    </div>
                    
                    {/* <div class="share-icons">
                        <p>Поделиться контактом:</p>
                        <img src={BigWhatsAppIcon} alt="WhatsApp" class="social-icon" onClick={sendToWhatsapp}/>
                        <img src={VkIcon} alt="VK" class="social-icon" />
                        <img src={TelegramIcon} alt="Telegram" class="social-icon" />
                    </div> */}
                    <div class="popup-buttons">
                        <button class="cancel-btn" onClick={onClose}>Отмена</button>
                        <button class="whatsapp-btn" onClick={sendToWhatsapp}>
                          Написать в WhatsApp
                          <img src={WhatsAppIcon}/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Popup;