import React from "react";
import "./AreasOfActivityCard.css";
import ClosetImage from '../resources/areas_of_activity_closet.svg';

const ItemComponent = ({ text }) => {
    return (
        <div className="ItemComponent">
            <div className="ItemText">{text}</div>
        </div>
    );
};

const AreasOfActivityCard = () => {
    return (
        <div className="AreasOfActivityCard_bg">
            <div className="Background"></div>
            <div className="CardTitle">Основные направления деятельности:</div>
            <img className="ClosetImage" src={ClosetImage} alt="Фон" /> 

            {/* <div className="ItemsContainer">
                <div className="ItemWrapper">
                    <ItemComponent text="Системы управления" />
                </div>
                <div className="ItemWrapper">
                    <ItemComponent text="Электрическое оборудование" />
                </div>
                <div className="ItemWrapper">
                    <ItemComponent text="Системы электропривода" />
                </div>
                <div className="ItemWrapper">
                    <ItemComponent text="Механическое оборудование" />
                </div>
            </div> */}
        </div>
    );
};

export default AreasOfActivityCard;