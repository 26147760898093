import * as React from "react";

import ImageAndText from "../Components/ImageAndText/ImageAndText.js"
import constants from "../ContentConstants.js"

import './Header.css'

import CompanyLogo from '../resources/company_logo_extendend.svg'

import PhoneImage from '../resources/phone.svg'
import MailImage from '../resources/mail.svg'

export default function Header({openPopupHandler}) {
    return (
        <header className="header">
            <div className="grid-item start-1">
                <img    
                    src={CompanyLogo} 
                    alt="Система техники"   
                />
            </div>

            <div className="grid-item start-5">
                <ImageAndText   src = {PhoneImage} 
                                text = {constants.companyPhoneNumber}
                            />
            </div>
            
            <div className="grid-item start-8">
                <ImageAndText   src = {MailImage}
                                text = {constants.companyEmail}
                                />
            </div>
            <div className="grid-item start-11">
                <button className="reach-us-button" onClick={openPopupHandler}>Связаться с нами</button>
            </div>

        </header>
    );
}