import React from "react";
import "./ExperienceCard.css";

import BgImage from '../resources/experience_background.png'
import Cards from '../resources/experience_cards.svg'

const ExperienceCard = () => {
  return (
    <div class="ExperienceCard-background"  style={{ backgroundImage: `url(${BgImage})` }}>
        <div class="ExperienceCard_container">
            <h2 className="ExperienceCard-title">Опыт реализации проектов:</h2>
            <img src={Cards}></img>
        </div>

    </div>
  );
};

export default ExperienceCard;
