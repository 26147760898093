import React, { useState } from 'react';

import Header from './Header/Header';

import IntroCard from './IntroCard/IntroCard';
import OurPrinciplesCard from './OurPrinciplesCard/OurPrinciplesCard';
import AreasOfActivityCard from './AreasOfActivityCard/AreasOfActivityCard'
import WeSuggestCard from './WeSuggestCard/WeSuggestCard'

import TechnicalSupport from './TechnicalSupport/TechnicalSupport'
import DevelopTechDocCard from './DevelopTechDocCard/DevelopTechDocCard'
import WhyWeTrusted from './WhyWeTrusted/WhyWeTrusted';
import Footer from './Footer/Footer'

import ContactUsPopup from './ContactUsPopup/ContactUsPopup'
import ExperienceCard from './ExperienceCard/ExperienceCard'

import './Stylesheet.css'



function App() {

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  return (
    <>
      <div>

        <ContactUsPopup isOpen={isPopupOpen} onClose={closePopup}/>

        <Header openPopupHandler={openPopup}/>
        <IntroCard/>
        <AreasOfActivityCard/>
        <WeSuggestCard/>
        <OurPrinciplesCard/>
        <ExperienceCard/>
        <TechnicalSupport/>
        <DevelopTechDocCard/>
        <WhyWeTrusted openPopupHandler={openPopup}/>
        <Footer/>
      </div>
    </>

  );
}

export default App;
